<template>
    <div class="container">
      <div style="text-align:left;margin-bottom:15px" class="between flex">
        <span>
          <span>角色名</span>
          <span class="rolename">{{group_name}}</span>
        </span>
        <span>
          <el-button type="primary" style="width:92px" @click="submitRole">保存</el-button>
          <el-button plain style="width:92px" @click="back">退出</el-button>
        </span>
      </div>
      
      <!-- 角色权限配置 -->
      <div>
        <div class="role" v-for="(role1,index) in roleData" :key="index
        ">
          <div class="role1" @click="chooseRole1(role1,index)">
            <img src="../../../../public/img/not_check.png" alt v-if="!role1.isChecked" />
            <img src="../../../../public/img/check.png" alt v-if="role1.isChecked" />
            <span>{{role1.module_name}}</span>
          </div>
          <div class="role2">
            <div class="role2_item" v-for="(role2,ind) in role1.list" :key="ind">
              <div class="role2_item1" @click="chooseRole2(role1,role2,index,ind)">
                <img src="../../../../public/img/not_check.png" alt v-if="!role2.isChecked" />
                <img src="../../../../public/img/check.png" alt v-if="role2.isChecked" />
                <span>{{role2.module_name}}</span>
              </div>
              <div class="role2_item2">
                <div
                  v-for="(role3,i) in role2.list"
                  :key="i"
                  @click="chooseRole3(role1,role2,role3,index,ind,i)"
                >
                  <img src="../../../../public/img/not_check.png" alt v-if="!role3.isChecked" />
                  <img src="../../../../public/img/check.png" alt v-if="role3.isChecked" />
                  <span>{{role3.module_name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  data() {
    return {
      group_name: this.$route.query.group_name,
      check: require("@/assets/check.png"),
      not_check: require("@/assets/not_check.png"),
      roleData: [],
      role: {}
    };
  },
  methods: {
    getModuleList() {
      this.axios.post('/store/Admin/getModuleList').then(res => {
        if (res.code == 0) {
          let data = res.data.list;
          data.forEach(item => {
            item.isChecked = false;
            item.list.forEach(i => {
              i.isChecked = false;
              i.list.forEach(j => {
                j.isChecked = false;
              });
            });
          });
          this.roleData = data;
          let that = this;
          if (that.$route.query.module_id_array) {
            let rolelist = that.$route.query.module_id_array;
            let check_id = rolelist.split(",");
            that.roleData.forEach(item => {
              if (check_id.includes(item.module_id + "")) {
                item.isChecked = true;
              }
              item.list.forEach(i => {
                if (check_id.includes(i.module_id + "")) {
                  i.isChecked = true;
                }
                i.list.forEach(j => {
                  if (check_id.includes(j.module_id + "")) {
                    j.isChecked = true;
                  }
                });
              });
            });
          }
        }
      });
    },
    //选择一级角色
    chooseRole1(role1, index) {
      if (this.$route.query.group_id == 1) {
        return false;
      }
      role1.isChecked = !role1.isChecked;
      if (role1.isChecked) {
        this.roleData[index].list.forEach(i => {
          i.isChecked = true;
          i.list.forEach(j => {
            j.isChecked = true;
          });
        });
      } else {
        this.roleData[index].list.forEach(i => {
          i.isChecked = false;
          i.list.forEach(j => {
            j.isChecked = false;
          });
        });
      }
    },
    //选择二级角色
    chooseRole2(role1, role2, index, ind) {
      if (this.$route.query.group_id == 1) {
        return false;
      }
      role2.isChecked = !role2.isChecked;
      role1.isChecked = false;
      role2.list.forEach(ele => {
        ele.isChecked = role2.isChecked;
      });
      this.roleData[index].list.forEach(i => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          role1.isChecked = true;
        }
      });
    },
    //选择三级角色
    chooseRole3(role1, role2, role3, index, ind) {
      if (this.$route.query.group_id == 1) {
        return false;
      }
      role3.isChecked = !role3.isChecked;
      role1.isChecked = false;
      role2.isChecked = false;
      this.roleData[index].list[ind].list.forEach(i => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          role2.isChecked = true;
        }
      });
      this.roleData[index].list.forEach(i => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          role1.isChecked = true;
        }
      });
    },
    //提交选择的角色分配
    submitRole() {
      let check_id = [];
      this.roleData.map(item => {
        if (item.isChecked) {
          check_id.push(item.module_id);
        }
        item.list.map(i => {
          if (i.isChecked) {
            check_id.push(i.module_id);
          }
          i.list.map(j => {
            if (j.isChecked) {
              check_id.push(j.module_id);
            }
          });
        });
      });
      let module_id_array = check_id.toString();
      let group_id = this.$route.query.group_id;
      this.axios.post('/store/Admin/setModule',{ group_id, module_id_array }).then(res => {
        if (res.code == 0) {
          this.$message.success("设置成功");
          history.back();
        }
      });
    },
    //退出
    back() {
      this.$confirm("是否放弃设置角色权限？", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        history.back();
      });
    }
  },
  created() {
    this.getModuleList();
  }
};
</script>

<style lang="scss" scoped>


.rolename {
  display: inline-block;
  background-color: #1890ff;
  border-radius: 2px;
  margin-left: 20px;
  color: #fff;
  padding: 4px 6px;
}

.role:first-child {
  border-top: 1px solid #cfcece;
}
.role {
  width: 100%;
  display: flex;
  border: 1px solid #cfcece;
  border-top: 1px solid transparent;
  justify-content: space-between;
  span {
    margin-left: 5px;
  }
  .role1 {
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #cfcece;
    cursor: pointer;
    min-height: 70px;
    box-sizing: border-box;
  }
  .role2 {
    flex: 1;
    .role2_item {
      display: flex;
      border-bottom: 1px solid #cfcece;
      box-sizing: border-box;

      .role2_item1 {
        width: 190px;
        border-right: 1px solid #cfcece;
        box-sizing: border-box;

        line-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .role2_item2 {
        width: 660px;
        display: flex;
        flex-wrap: wrap;
        div {
          line-height: 50px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .role2_item:last-child {
      border-bottom: 1px solid transparent;
    }
  }
}
</style>

